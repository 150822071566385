<script setup lang="ts">
import { useRootStore } from "@/store/root";
import TypeText from "@/components/marketing/TypeText.vue";
import AppLogo from "@/layouts/partials/marketing/AppLogo.vue";

const rootStore = useRootStore();
const config = useRuntimeConfig();

const menus: { name: string; links: { name: string; route?: string; target?: string; action?: () => void }[] }[] =
  [
    {
      name: "Employers",
      links: [
        {
          name: "Getting started",
          route: ROUTES.employers,
        },
        {
          name: "Features",
          route: ROUTES.employersFeatures,
        },
        {
          name: "Pricing",
          route: ROUTES.employersPricing,
        },
        // {
        //   name: "Browse candidates",
        //   route: ROUTES.employersBrowseCandidates,
        // },
      ],
    },
    {
      name: "Candidates",
      links: [
        {
          name: "Getting started",
          route: ROUTES.candidates,
        },
        {
          name: "Features",
          route: ROUTES.candidatesFeatures,
        },
        {
          name: "Download the app",
          action: () => rootStore.toggleDownloadAppModal(true),
        },
      ],
    },
    {
      name: config.public.appName,
      links: [
        //TODO: Re-enable blog when we have more content
        // {
        //   name: "Blog",
        //   route: ROUTES.blog,
        // },
        {
          name: "Contact",
          route: ROUTES.contact,
        },
        {
          name: "Delete account",
          route: ROUTES.howToDeleteAccount,
        },
      ],
    },
    {
      name: "Connect",
      links: [
        {
          name: "Facebook",
          route: EXTERNAL_LINKS.facebook,
          target: "_blank",
        },
        {
          name: "Instagram",
          route: EXTERNAL_LINKS.instagram,
          target: "_blank",
        },
        {
          name: "Twitter",
          route: EXTERNAL_LINKS.twitter,
          target: "_blank",
        },
        {
          name: "LinkedIn",
          route: EXTERNAL_LINKS.linkedIn,
          target: "_blank",
        },
        {
          name: "TikTok",
          route: EXTERNAL_LINKS.tiktok,
          target: "_blank",
        },
      ],
    },
  ];
</script>

<template>
  <footer class="flex flex-col items-center justify-center w-full bg-white-gray">
    <div class="container max-w-[1400px] mx-auto">
      <div class="grid grid-cols-10 gap-5 row pb-10 px-10 xl:px-0 pt-[52px]">
        <div class="col-span-10 mt-12 lg:col-span-2">
          <AppLogo />
          <div class="mt-6 text-sm">
            <TypeText text="Placing people, not paper." />
          </div>
        </div>
        <div v-for="menu in menus" :key="menu.name" class="col-span-10 mt-12 lg:col-span-2">
          <h6 class="mb-4 font-primary font-semibold lg:mb-8">{{ menu.name }}</h6>
          <ul>
            <li
              v-for="(nav, index) in menu.links"
              :key="index"
              :class="menu.links.length - 1 === index ? 'mb-0' : 'mb-2'"
            >
              <div v-if="nav.action" @click="nav.action" class="footer-link">{{ nav.name }}</div>
              <NuxtLink v-else :to="nav.route" :target="nav.target" class="footer-link">{{ nav.name }}</NuxtLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container max-w-[1400px] px-5 mx-auto">
      <div class="mx-auto border-t py-7 text-center">
        <p class="text-sm text-dark-gray">
          Copyright © {{ dayjs().year() }} {{ $config.public.appName }} | All Rights Reserved |
          <span class="link" @click="navigateTo(ROUTES.privacyPolicy)">Privacy Policy</span> |
          <span class="link" @click="navigateTo(ROUTES.termsAndConditions)">Terms & Conditions</span>
        </p>
      </div>
    </div>
  </footer>
</template>
